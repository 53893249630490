import React, {useEffect} from 'react';
import {embedDashboard} from '@superset-ui/embedded-sdk';
import {useParams} from 'react-router-dom';

const SupersetDashboard = () => {

  const { dashboardID } = useParams();
  const supersetUrl = 'https://superset.datenraum.freiburg.de';
  const backendUrl = 'https://superset-embedding-backend.datenraum.freiburg.de';
  useEffect(() => {
    async function fetchToken() {
      const token = await getTokenLocal();
      if (token) {
        embedDashboard({
          id: dashboardID,
          supersetDomain: supersetUrl,
          mountPoint: document.getElementById("dashboard"),
          fetchGuestToken: () => token,
        });
        document.getElementById("dashboard").children[0].width="100%";
        document.getElementById("dashboard").children[0].height="100%";
      }
    }
    fetchToken();
  }, [dashboardID]);

    async function getTokenLocal() {
    try {
      const response = await fetch(`${backendUrl}:443/get_guest_token?dashboard=${dashboardID}`);
      const data = await response.json();
      return data.message;
    } catch (error) {
      return null;
    }
  }
  return (
    <div id='dashboard' style={{width: '100%', height: '100vh'}}/>
  );
};
export default SupersetDashboard;
